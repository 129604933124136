<template>
  <div class="base flex">
    <div class="main">
<!--      <Title />-->
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { setNavbar } from "@/mixins/setNavbar";
import { setFooter } from "@/mixins/setFooter";
import { setGnb } from "@/mixins/setGnb";
//import Title from "@/views/eventController/LiveSiteMonitoringTitle";
import { fetchEvent } from "@/api/event";
import { mapState } from "vuex";



export default {
  mixins: [format, setNavbar, setFooter, setGnb],
  components: {  },
  data() {
    return {
      event: {},
      department: {},
    };
  },
  created() {
    this.id = this.$route.params.eventId;
  },
  async mounted() {
    this.getEventDetail();
    if (this.$route.query.new) {
      this.setNavbar(false);
      this.setGnb(false);
    }
    // this.setNavbar(false);
    this.setFooter(false);
  },
  computed: {
    ...mapState({
      navVisible: state => state.store.navVisible,
      gnbVisible: state => state.store.gnbVisible,
      siteVisible: state => state.store.siteVisible,
    }),
  },
  methods:{
    goHome() {
      this.visible = 1;
    },
    preset(){
   /*   let id = localStorage.getItem("LiveEventInfoMain");
      if(id != undefined) {
        this.visible = id;
      } else {
        if(this.visible == null){
          this.visible = 1;
        }
        localStorage.setItem("LiveEventInfoMain", this.visible);
      }*/
    },
    getEventDetail() {
      if(this.id == undefined) {
        return;
      }
      fetchEvent(this.id).then((res) => {
        this.event = res.data.data;
        this.department = res.data.data.managerDepartment;
        if(!this.$store.state.store.isAdmin && this.event.managerDepartment.departmentId != this.$store.state.store.departmentId){
          var filter = this.event.departmentList.filter(item => item.departmentId == this.$store.state.store.departmentId);
          if(this.event.isAuth == true && filter.length == 0) {
            alert(this.$t("user-not-permission"));
            //this.$router.push({path:"liveListControl"});
          }
        }
        this.preset();
      });
    },
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setGnb(true);
    this.setFooter(true);
  },
};
</script>
